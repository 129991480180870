import React from "react";
import {
  NotFoundTitle,
  Container,
  Wrapper,
  Title,
  Description,
  Link,
} from "../404/not-found.styles";

const NotFound: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <NotFoundTitle>404</NotFoundTitle>
        <Title>Página não encontrada!</Title>
        <Description>
          Desculpe, mas a página que você está procurando não foi encontrada.
          Volte para a <Link to="/">página inicial</Link>
        </Description>
      </Wrapper>
    </Container>
  );
};

export default NotFound;
