import styled from "styled-components";
import { Link as LinkRoute } from "gatsby";

export const Container = styled.section`
  display: flex;
  margin: 0 auto;
  min-width: 100%;
`;

export const NotFoundTitle = styled.h1`
  font-size: 5rem;
`;

export const Wrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

export const Title = styled.h1`
  font-size: 2.1rem;
  margin-top: 1.5rem;
`;

export const Description = styled.p`
  font-size: 1.1rem;
  margin-top: 1.5rem;

  @media screen and (min-width: 768px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
`;

export const Link = styled(LinkRoute)`
  text-decoration: none;
  cursor: pointer;
  color: #6ed796;
`;
