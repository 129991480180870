import * as React from "react";
import Layout from "../components/layout";
import Sidebar from "../components/organisms/Sidebar/sidebar.components";
import Nav from "../components/organisms/Nav/nav.components";
import NotFound from "../components/organisms/404/not-found.components";
import SEO from "../components/seo";

const NotFoundPage: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const toggle = () => {
    setOpen(!open);
  };
  return (
    <Layout>
      <SEO title="Ops... a página não foi encontrada" />
      <Sidebar open={open} toggle={toggle} />
      <Nav toggle={toggle} open={false} />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
